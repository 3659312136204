import React, { useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, Text } from '../components/primitives'

export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Allmäna villkor | Hemset.se'}
      blackNav={true}
      >
      <Flex jusifyContent={'center'}>
        <Flex
          mt={['80px', '80px', '80px']}
          maxWidth={'1000px'}
          minHeight={'100vh'}
          p={4}
          flexDirection={'column'}>
          <Text fontWeight={'bold'} fontSize={8} mb={2}>
            Allmänna villkor
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Allmänt
          </Text>
          <Text mb={2}>
            Vi som tillhandahåller sajten www.hemset.se Hemset AB, org. nr
            559089-0736, 21421 Malmö, Sverige ("Hemset"). Med benämningarna "vi,
            våra, vårt och oss" nedan avses Hemsets.
          </Text>
          <Text mb={2}>
            För att kunna registrera ett användarkonto hos oss, lägga en
            beställning eller för att kunna köpa produkt(er) genom vår sajt
            måste du vara 18 år. Om du är under 18 år måste du ha målsmans
            godkännande. Genom köp av produkt(er) ur vårt sortiment, tecknande
            av försäkring genom oss, ansökan om kredit, registrering av
            användarkonto hos oss, beställning av vår katalogprenumeration,
            användande av några av våra tjänster på vår sajt och/eller
            användande av någon av våra mobiltelefonapplikationer godkänner du
            våra vid var tid gällande Allmänna villkor och intygar därmed att de
            uppgifter som du lämnar om dig själv är riktiga.
          </Text>
          <Text mb={2}>
            Vi har rätt att ensidigt ändra dessa Allmänna villkor och de
            eventuella förändringar som vi gör träder i kraft tidigast 30 dagar
            efter det att de justerade villkoren har gjorts tillgängliga på vår
            sajt.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Företagsinformation
          </Text>
          <Text mb={2}>Telefon: 070-490 72 59</Text>
          <Text mb={2}>
            Öppna telefontider: Måndag till fredag: 7.30 - 21.00, lördag: 9.00 -
            17.00, söndag: 9.00 - 21.00
          </Text>
          <Text mb={2}>Adress: Scheelevägen 27 223 70 Lund</Text>
          <Text mb={2}>Organisationsnummer: 559089-0736</Text>
          <Text mb={2}>E-postadress: info@hemset.se</Text>
          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Priser och avgifter
          </Text>
          <Text mb={2}>
            Priserna för produkterna kan ändras av omständigheter som vi inte
            råder över, till exempel väsentligt ändrade råvarupriser,
            valutaförändringar eller ändrad moms. I alla priser ingår moms om 25
            %. Om momsen ändras förbehåller vi oss rätten att justera våra
            priser och avgifter med motsvarande belopp. Vi reserverar oss för
            eventuella tryck- och skrivfel.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Våra avgifter för närvarande
          </Text>
          <Text mb={2}>
            En avgift på 1950 för varje påbörjat inredningskoncept och rum.
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Betalsätt
          </Text>
          <Text mb={2}>
            Vi använder oss av Klarna där både faktura och kortbetalning är
            möjligt.{' '}
          </Text>
          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Faktura
          </Text>
          <Text mb={2}>
            Klarnas faktura skickas direkt till den mejladress du angav vid
            köptillfället. Du kan också enkelt se och hantera dina fakturor
            genom att logga in på www.klarna.se eller i Klarnas app.
          </Text>

          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Support och tillgänglighet
          </Text>
          <Text mb={2}>
            Vi besvarar frågor via e-post inom 24 timmar från det att vi
            mottagit aktuell förfrågan. Tidsgarantin gäller dock endast
            förfrågningar som avser faktiska köp och beställningar samt under
            vardagar. I de fall vi inte lever upp till tidsgarantin ser vi till
            att inom 24 timmar meddela kund om fördröjningen och orsaken till
            denna.
          </Text>

          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Nyhetsbrevsprenumeration
          </Text>

          <Text mb={2}>
            Genom köp av produkt(er) ur vårt sortiment påbörjar du automatiskt
            en prenumeration av vårt nyhetsbrev. Skulle nyhetsbrevet utebli ber
            vi dig kontakta oss på ovan angivna sätt. Du kan närsomhelst säga
            upp prenumerationen av Hemsets nyhetsbrev.
          </Text>

          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Övrigt
          </Text>

          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Skicka inga förskott.
          </Text>
          <Text mb={2}>
            Vi ansvarar inte för pengar, checkar och frimärken som skickas i
            brev.
          </Text>

          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Bedrägeri
          </Text>
          <Text mb={2}>
            Vi polisanmäler alla bedrägerier och dataintrång samt försök till
            sådana. Vi förbehåller oss rätten att neka eller häva ditt köp i det
            fall vi har skäl att misstänka bedrägerier, dataintrång och/eller
            annan sorts missbruk av vår sajt.
          </Text>

          <Text mb={2} fontWeight={'bold'} fontSize={6}>
            Force majeure
          </Text>
          <Text mb={2}>
            Vi är befriade från påföljd för underlåtenhet att fullgöra viss
            förpliktelse enligt dessa Allmänna villkor, om underlåtenheten har
            sin grund i befriande omständighet enligt nedan och omständigheten
            förhindrar, försvårar eller försenar fullgörandet. Befriande
            omständigheter är bland annat åtgärder eller underlåtenhet från
            myndighet, ny eller ändrad lagstiftning, konflikt på
            arbetsmarknaden, blockad, brand, översvämning, sabotage eller
            olyckshändelse av större omfattning. I force majeure ingår
            myndighetsbeslut som påverkar marknad och produkter negativt, till
            exempel beslut om varningstexter, försäljningsförbud med mera eller
            en onormal nedgång i marknaden.
          </Text>
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
